<template>
  <div class="account">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>批次管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- content -->
    <div class="accountMain">
      <!-- table top -->
      <div class="accTableTop">
        <div class="top-left">
          <div class="accCreate" @click="modalShow">创建激活码</div>
          <div class="accCreate" @click="importModalShow">导入保单</div>
        </div>
      </div>
      <!-- table -->
      <batchTable ref="childrene" />
    </div>
    <!-- 创建激活码 -->
    <el-dialog :visible.sync="dialogFormVisible" width="34%" center>
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="temp"
        label-position="left"
        label-width="80px"
        style="width: 500px; margin-left: 30px"
      >
        <el-form-item label="批次标题" prop="title">
          <el-input v-model="temp.title" type="text" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="描述" prop="descInfo">
          <el-input v-model="temp.descInfo" type="text" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="生成数量" prop="count">
          <el-input
            v-model.number="temp.count"
            type="text"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="使用次数" prop="useCount">
          <el-input
            v-model.number="temp.useCount"
            type="text"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="modalShow">取 消</el-button>
        <el-button type="primary" @click="confirmCreate">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导入保单 -->
    <el-dialog :visible.sync="dialogModalVisible" width="34%" center>
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="temp"
        label-position="left"
        label-width="80px"
        style="width: 500px; margin-left: 30px"
      >
        <el-form-item label="使用次数" prop="useCount">
          <el-input
            v-model.number="temp.useCount"
            type="number"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          label="保单文件"
          v-if="temp.useCount && temp.useCount > 0"
        >
          <el-upload
            class="upload-demo"
            action="/api/tBatch/importPolicy"
            :limit="1"
            accept=".xls,.xlsx"
            :data="{ useCount: temp.useCount }"
            :headers="headers"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="importModalShow">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import batchTable from '@/components/table/batchTable.vue';
import { saveTBatch } from '@/api/batch.js';

export default {
  beforeRouteLeave(to, from, next) {
    from.meta.keepAlive = false;
    next();
  },
  components: {
    batchTable
  },
  data() {
    return {
      headers: { Authorization: localStorage.getItem('token') },
      dialogFormVisible: false, //创建激活码弹窗控件
      dialogModalVisible: false, //导入保单弹窗控件
      rules: {
        title: [{ required: true, message: '必填信息' }],
        descInfo: [{ required: true, message: '必填信息' }],
        count: [
          { required: true, message: '必填' },
          { type: 'number', message: '必须是数字' }
        ],
        useCount: [
          { required: true, message: '必填' },
          { type: 'number', message: '必须是数字' }
        ]
      },
      temp: {
        title: '',
        descInfo: '',
        count: '',
        useCount: ''
      },
      fileList: []
    };
  },

  mounted() {},

  methods: {
    handleUploadSuccess(res) {
      console.log(res);
      this.dialogModalVisible = false;
      this.$refs.childrene.getData(1, 10, null, null);
      if (res.status == 200) {
        this.$notify({
          title: '成功',
          message: '导入成功',
          type: 'success',
          duration: 2000
        });
      } else {
        this.$message.error(res.message);
      }
    },
    //创建激活码
    importModalShow() {
      this.dialogModalVisible = !this.dialogModalVisible;
      this.temp = {
        useCount: ''
      };
    },
    //创建激活码
    modalShow() {
      this.dialogFormVisible = !this.dialogFormVisible;
      this.temp = {
        title: '',
        descInfo: '',
        count: '',
        useCount: ''
      };
    },
    //确定
    confirmCreate() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let formData = Object.assign({}, this.temp);
          saveTBatch(formData).then(() => {
            this.$refs.childrene.getData(1, 10, null, null);
            this.dialogFormVisible = false;
            this.$notify({
              title: '成功',
              message: '创建成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="stylus">
//change elemen-ui style

//面包屑样式
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }

//narmoal
.account
  overflow hidden
  .accountMain
    overflow hidden
    margin-top: 16px
    padding: 20px
    background-color: #FFFFFF
    .accTableTop
      display: flex
      justify-content: space-between
      .top-left
        display: flex
        .accCreate
          width: 116px
          height 32px
          margin-right: 24px
          background-color:#00CCCC
          cursor pointer
          color: #fff
          line-height: 32px
          text-align: center
          font-size: 14px
      .top-right
        display: flex
        .accSearch
          height: 32px
          margin-left: 24px
</style>
