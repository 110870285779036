<template>
  <div class="table">
    <el-table
      ref="multipleTable"
      :data="tableData"
      :highlight-current-row="true"
      tooltip-effect="dark"
      style="width: 100%; color: #858585"
      :header-cell-style="tableHeaderCellStyle"
    >
      <!-- <el-table-column
      @selection-change="selectRow"
     type="selection"
     fill="#00CCCC"
     fixed="left">
    </el-table-column> -->
      <!-- 表头开始 -->
      <el-table-column label="批次标题" width="170" prop="title" align="center">
      </el-table-column>
      <el-table-column
        label="描述"
        prop="descInfo"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="生成数量"
        prop="count"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column prop="useCount" label="使用次数" align="center">
      </el-table-column>

      <el-table-column
        prop="createTime"
        align="center"
        label="创建时间"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column align="center" label="操作" width="220">
        <template slot-scope="scope">
          <span style="color: #00cccc; cursor: pointer;" @click="clickRow(scope.row)">查看详情</span>
          <span style="color: #00cccc; cursor: pointer;margin-left: 10px;" @click="downloadRow(scope.row)">下载激活码</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="page">
      <!-- left -->
      <span class="pageLeft"
        >共 {{ count }} 条记录 第 {{ currentPage }} / {{ tatalPage }}页</span
      >
      <!-- right -->
      <el-pagination
        @size-change="SizeChange"
        background
        @current-change="CurrentChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="onlyPage"
        :pager-count="5"
        layout="sizes, prev, pager, next, jumper"
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { findTBatchPage, exportCodeExcel } from '@/api/batch.js';

export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      // 分页相关
      currentPage: 1, // 当前页面,默认1
      tatalPage: 0, //总页数,为加载时先为0，页面首次加载后去请求即可
      count: 0, //总的条数记录
      onlyPage: 10 // 当前页面可以展示多少条数据,默认10
    };
  },
  mounted() {
    //初始化获取列表数据
    this.getData(1, 10, null, null);
  },

  //监听勾选值
  watch: {
    multipleSelection: {
      handler(pre) {
        var tem = pre;
        if (tem.length == 0) {
          this.$parent.isDisable = true;
        }
        if (tem.length == 1) {
          this.$parent.isDisable = false;
          //console.log(tem);
          this.$parent.tableSelect = tem[0];
          if (tem[0].enabled == '启用') {
            this.$parent.statusV = '禁用';
          } else {
            this.$parent.statusV = '启用';
          }
        }
        if (tem.length > 1) {
          this.$parent.isDisable = true;
          this.$message({
            message: '当前仅支持下载单个数据！',
            type: 'warning'
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    //表头样式回调
    tableHeaderCellStyle() {
      return 'color:#fff;background-color:#00CCCC';
    },
    // getData
    getData(currentPage, onlyPage, accStatus, all) {
      const rLoading = this.openLoading(); // 开始loading
      findTBatchPage({
        currentPage: currentPage,
        pageSize: onlyPage,
        enabled: accStatus,
        username: all
      })
        .then((res) => {
          if (res.data.status == 200) {
            this.tableData = res.data.data.records;
            this.tatalPage = parseInt(res.data.data.size);
            this.count = parseInt(res.data.data.total);
            rLoading.close(); // 关闭loading
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((res) => {
          this.$message.error(res);
        });
    },
    // 复选框选项
    selectRow(val) {
      //console.log(this.multipleSelection);
      this.multipleSelection = val;
    },

    // 分页相关
    SizeChange(val) {
      this.onlyPage = val;
      this.getData(
        this.currentPage,
        this.onlyPage,
        this.$parent.accStatus,
        this.$parent.accAllSearch
      ); //更新数据
    },
    CurrentChange(val) {
      this.currentPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(
        this.currentPage,
        this.onlyPage,
        this.$parent.accStatus,
        this.$parent.accAllSearch
      );
    },
    prevClick(val) {
      this.currentPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(
        this.currentPage,
        this.onlyPage,
        this.$parent.accStatus,
        this.$parent.accAllSearch
      );
    },
    nextClick(val) {
      this.currentPage = val;
      //this.getData(this.currentPage,this.onlyPage);//更新数据
      this.getData(
        this.currentPage,
        this.onlyPage,
        this.$parent.accStatus,
        this.$parent.accAllSearch
      );
    },
    clickRow(e) {
      this.$router.push({
        path: '/activationCode',
        query: {
          id: e.id
        }
      });
    },
    // 下载
    downloadRow(row) {
      const loading = this.$loading({
        lock: true,
        text: '下载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      exportCodeExcel({ id: row.id })
        .then((res) => {
          if (!res || !res.data) {
            this.$message.error('暂无');
          }
          if (res.data.type === 'application/json') {
            const reader = new FileReader()
            reader.readAsText(res.data)
            reader.onload = e => {
              const resData = JSON.parse(reader.result)
              if(resData.status!=200){
                loading.close();
                this.$message.error(resData.message)
              }
            }
          }
          else {
            const { data, headers } = res
            const newHeader = decodeURI(headers['content-disposition']);//进行解码  decodeURI()方式解码
            const fileName = newHeader.split(';')[1].split('=')[1];//提取文件名称
            const blob = new Blob([data], { type:'application/x-excel'})
            let dom = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(fileName)
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click();
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
            loading.close();
          }
        }).catch((res) => {
          this.$message.error(res);
          setTimeout(()=>{
            loading.close();
          },1000)
        });    
    }
  }
};
</script>
<style scoped>
.table {
  margin-top: 0.1563rem;
}
.el-button {
  color: #00a3a3 !important;
}
.action {
  display: flex;
  flex-direction: column;
}
.actionP {
  cursor: pointer;
  color: #00cccc;
  margin: 0 !important;
}
.resoult {
  text-align: left;
}
.page {
  display: flex;
  margin-top: 0.3125rem;
  justify-content: space-between;
  align-items: center;
}
.pageLeft {
  color: #858585;
  font-size: 0.1094rem;
  line-height: 0.1875rem;
}
.el-pagination {
  color: #858585 !important;
  font-size: 0.1094rem !important;
  line-height: 0.1875rem !important;
}
</style>
